"use client"

import WidgetLibras from "@/components/ui/accessibility/WidgetLibras"
import { MessageSnackbar } from "@/components/ui/snackbar/MessageSnackbar"
import { AuthProvider } from "@/contexts/AuthProvider"
import { SWRConfigProvider } from "@/contexts/SWRConfigContext"
import { baseTheme } from "@/styles/theme"
import { isDevelopmentMode } from "@/utils/environmentUtils"
import { CssBaseline } from "@mui/material"
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter"
import { ThemeProvider } from "@mui/material/styles"
import Script from "next/script"
import React, { memo } from "react"

export function ClientLayout({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const gtmId = process.env.NEXT_PUBLIC_G_TAG_MANAGER_ID ?? ""
  return (
    <>
      <noscript>
        <iframe
          title="Google Tag Manager (noscript)"
          id="gtm-body"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          loading="lazy"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <AppRouterCacheProvider>
        <ThemeProvider theme={baseTheme}>
          <CssBaseline />
          <AuthProvider>
            <SWRConfigProvider>
              <div id="message-spot" aria-live="polite"></div>
              <main>{children}</main>
              <MessageSnackbar />
            </SWRConfigProvider>
          </AuthProvider>
        </ThemeProvider>
      </AppRouterCacheProvider>
      {!isDevelopmentMode() && (
        <>
          <Script
            src="https://cdn.userway.org/widget.js"
            data-account="nZyGsommUy"
            async
          ></Script>
          <WidgetLibras />
        </>
      )}
    </>
  )
}

export default memo(ClientLayout)
