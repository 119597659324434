"use client"

import { lato, roboto } from "@/styles/fonts"
import { ptBR as corePtBR } from "@mui/material/locale"
import { ThemeOptions, createTheme } from "@mui/material/styles"
import { deepmerge } from "@mui/utils"
import { ptBR as datagridPtBR } from "@mui/x-data-grid/locales"
import { ptBR as datePickersPtBR } from "@mui/x-date-pickers/locales"

declare module "@mui/material/styles" {
  //TODO Necessário?
  interface Palette {
    blueMsGov: Palette["primary"]
    greenMsGov: Palette["primary"]
    placeholder: Palette["primary"]
  }
  //TODO Necessário?
  interface PaletteOptions {
    blueMsGov: PaletteOptions["primary"]
    greenMsGov: PaletteOptions["primary"]
    placeholder: PaletteOptions["primary"]
  }

  interface Theme {
    appHeader: {
      height: number
    }
    navigationDrawer: {
      openedWidth: number
      closedWidth: number
    }
    mobileNavigationDrawer: {
      openedHeight: number
    }
  }

  interface ThemeOptions {
    appHeader?: {
      height?: number
    }
    navigationDrawer?: {
      openedWidth?: number
      closedWidth?: number
    }
    mobileNavigationDrawer?: {
      openedHeight?: number
    }
  }
}

const baseThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.25rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      ::-webkit-scrollbar { 
         width: 7px;
         } 
      ::-webkit-scrollbar-track {
         background: transparent; 
         } 
      ::-webkit-scrollbar-thumb {
         background-color: #C6D3E8;
         border-radius: 4px;
         transition: background-color 0.3s, width 0.3s, height 0.3s;
         } 
      ::-webkit-scrollbar-thumb:hover {
         background-color: #a0b8d8;
         width: 10px; height: 10px;
         } 
      ::-webkit-scrollbar-thumb:active {
         background-color: #809ac0;
         width: 10px;
         height: 10px;
         }
      `,
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#f0f5fa",
      paper: "#ffffff",
    },
    text: {
      primary: "rgba(0,0,0,0.75)",
      secondary: "rgba(0,0,0,0.6)",
    },
    error: {
      main: "#e57373",
    },
    warning: {
      main: "#fb9949",
    },
    info: {
      main: "#2d78a7",
      light: "#03a9f4",
    },
    primary: {
      main: "#2555ab",
      light: "#5077bb",
      dark: "#193b77",
    },
    blueMsGov: {
      main: "#2555ab",
      light: "#5077bb",
      dark: "#193b77",
    },
    greenMsGov: {
      main: "#009640",
      light: "#9dcc90",
      dark: "#00692c",
    },
    placeholder: {
      main: "#49454f",
      light: "#6d6a72",
      dark: "#333037",
    },
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
    h1: {
      fontFamily: lato.style.fontFamily,
    },
    h2: {
      fontFamily: lato.style.fontFamily,
    },
    h3: {
      fontFamily: lato.style.fontFamily,
    },
    h4: {
      fontFamily: lato.style.fontFamily,
    },
    h5: {
      fontFamily: lato.style.fontFamily,
      fontWeight: 700,
    },
    h6: {
      fontFamily: lato.style.fontFamily,
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 16,
  },
  appHeader: {
    height: 100,
  },
  navigationDrawer: {
    openedWidth: 250,
    closedWidth: 90,
  },
  mobileNavigationDrawer: {
    openedHeight: 325,
  },
}

const baseTheme = createTheme(baseThemeOptions, corePtBR, datePickersPtBR, datagridPtBR)

const applicantsTheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#1d4f91", // Azul mais vibrante
        light: "#4f79b9",
        dark: "#13336a",
      },
      secondary: {
        main: "#006e33", // Verde mais claro
        light: "#66bb6a",
        dark: "#004421",
      },
      background: {
        default: "#f0f5fa",
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

const employerTheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#00a651", // Verde vibrante
        light: "#4fbf8a",
        dark: "#006b32",
      },
      secondary: {
        main: "#1e3a8a", // Azul calmo
        light: "#5069b0",
        dark: "#142050",
      },
      background: {
        default: "#F2FAF0",
      },
    },
    typography: {
      fontFamily: "'Open Sans', sans-serif",
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

const FUNTRABTheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#008a42", // Verde natural
        light: "#b6e0a7",
        dark: "#005e2d",
      },
      secondary: {
        main: "#1d3f91", // Azul suave
        light: "#507ab9",
        dark: "#132f71",
      },
      background: {
        default: "#EFEFEF",
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

const SEMADESCtheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#1d4f91", // Azul mais suave
        light: "#507ab9",
        dark: "#13336a",
      },
      secondary: {
        main: "#7cb342", // Verde vibrante
        light: "#aee571",
        dark: "#4b8305",
      },
      background: {
        default: "#E7EDF5",
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)
const IEPtheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#19A784",
        light: "#D6EADF",
        dark: "#139273",
      },
      secondary: {
        main: "#2555ab",
        light: "#5077bb",
        dark: "#193b77",
      },
      background: {
        default: "#EFFFF3",
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

const ADMINtheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#1c3c8a", // Azul mais escuro
        light: "#4a6ebb",
        dark: "#122057",
      },
      secondary: {
        main: "#505050", // Cinza mais escuro
        light: "#dcdcdc", // Mais suave
        dark: "#303030",
      },
      background: {
        default: "#f0f2f5",
      },
    },
    typography: {
      fontFamily: "'Source Sans Pro', sans-serif",
      h1: {
        fontFamily: "'Merriweather', serif",
        color: "#1c3c8a",
        fontWeight: 700,
      },
      h2: {
        fontFamily: "'Merriweather', serif",
        color: "#3c3c3c",
      },
      button: {
        fontWeight: 500,
        color: "#ffffff",
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: "0.25rem",
            fontWeight: 500,
          },
          standardError: {
            backgroundColor: "#ff4d4d",
            color: "#8a1e1e",
          },
          standardWarning: {
            backgroundColor: "#f5c542",
            color: "#1c3c8a",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "0.25rem",
            fontWeight: 600,
            textTransform: "none",
            "&.MuiButton-containedPrimary": {
              backgroundColor: "#1c3c8a",
              "&:hover": {
                backgroundColor: "#122057",
              },
            },
            "&.MuiButton-containedSecondary": {
              backgroundColor: "#505050",
              "&:hover": {
                backgroundColor: "#303030",
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
          },
        },
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

export {
  baseTheme,
  applicantsTheme,
  employerTheme,
  FUNTRABTheme,
  SEMADESCtheme,
  ADMINtheme,
  IEPtheme,
}
